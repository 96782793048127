var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "fieldset",
    [
      _c("legend", [_vm._v(" " + _vm._s(_vm.$t("lbl_sparepart")) + " ")]),
      _c("a-table", {
        attrs: {
          "data-source": _vm.store.form.spareParts,
          columns: _vm.columns,
          pagination: false,
          size: "small",
          scroll: { y: 600, x: "calc(100% + 200px)" },
          "row-selection": {
            selectedRowKeys: _vm.selectedRowKeys,
            onChange: _vm.onSelectChange
          }
        },
        scopedSlots: _vm._u([
          {
            key: "number",
            fn: function(text) {
              return [_vm._v(" " + _vm._s(_vm._f("qty")(text)) + " ")]
            }
          },
          {
            key: "title",
            fn: function() {
              return [
                _c(
                  "a-button-group",
                  [
                    _c("a-button", {
                      attrs: { icon: "plus", type: "primary" },
                      on: { click: _vm.addSparepart }
                    }),
                    _c("a-button", {
                      attrs: { icon: "delete", type: "danger" },
                      on: { click: _vm.handleRemove }
                    })
                  ],
                  1
                )
              ]
            },
            proxy: true
          },
          {
            key: "unitCode",
            fn: function(text, row) {
              return [
                _c("SelectUnitSparepart", {
                  staticClass: "w-100",
                  attrs: {
                    value: row.unitCode,
                    options: _vm.getAvailableUnits
                  },
                  on: {
                    "update:meta": function(ev) {
                      return _vm.onChangeUnitSparepart(row, ev)
                    }
                  }
                })
              ]
            }
          },
          {
            key: "productCode",
            fn: function(text, row) {
              return [
                _c("SelectWoPart", {
                  staticClass: "w-100",
                  attrs: { "option-label": "code" },
                  on: {
                    "update:meta": function(ev) {
                      return _vm.onChangeProduct(row, ev)
                    }
                  },
                  model: {
                    value: row.productCode,
                    callback: function($$v) {
                      _vm.$set(row, "productCode", $$v)
                    },
                    expression: "row.productCode"
                  }
                })
              ]
            }
          },
          {
            key: "productName",
            fn: function(text, row) {
              return [
                _c("SelectWoPart", {
                  staticClass: "w-100",
                  attrs: { "option-label": "name" },
                  on: {
                    "update:meta": function(ev) {
                      return _vm.onChangeProduct(row, ev)
                    }
                  },
                  model: {
                    value: row.productName,
                    callback: function($$v) {
                      _vm.$set(row, "productName", $$v)
                    },
                    expression: "row.productName"
                  }
                })
              ]
            }
          },
          {
            key: "productUom",
            fn: function(text, row) {
              return [
                _c("SelectPartUom", {
                  staticClass: "w-100",
                  attrs: {
                    "product-id": row.productCode ? row.productCode.key : ""
                  },
                  on: {
                    "update:meta": function($event) {
                      return _vm.onChangeUom(row)
                    }
                  },
                  model: {
                    value: row.productUom,
                    callback: function($$v) {
                      _vm.$set(row, "productUom", $$v)
                    },
                    expression: "row.productUom"
                  }
                })
              ]
            }
          },
          {
            key: "productLocation",
            fn: function(text, row) {
              return [
                _c("SelectPartLocation", {
                  staticClass: "w-100",
                  attrs: {
                    "branch-id": _vm.store.form.branch
                      ? _vm.store.form.branch.key
                      : "",
                    "product-id": row.productCode ? row.productCode.key : "",
                    "uom-id": row.productUom ? row.productUom.key : ""
                  },
                  on: {
                    "update:meta": function(ev) {
                      return _vm.onChangeProductLocation(row, ev)
                    }
                  },
                  model: {
                    value: row.productLocation,
                    callback: function($$v) {
                      _vm.$set(row, "productLocation", $$v)
                    },
                    expression: "row.productLocation"
                  }
                })
              ]
            }
          },
          {
            key: "qtyWorkOrder",
            fn: function(text, row) {
              return [
                _c("InputNumber", {
                  model: {
                    value: row.qtyWorkOrder,
                    callback: function($$v) {
                      _vm.$set(row, "qtyWorkOrder", _vm._n($$v))
                    },
                    expression: "row.qtyWorkOrder"
                  }
                })
              ]
            }
          },
          {
            key: "notes",
            fn: function(text, row) {
              return [
                _c("a-textarea", {
                  attrs: {
                    "auto-size": "",
                    placeholder: _vm.$t("common.insert-text", {
                      text: _vm.$t("lbl_notes")
                    })
                  },
                  model: {
                    value: row.notes,
                    callback: function($$v) {
                      _vm.$set(row, "notes", $$v)
                    },
                    expression: "row.notes"
                  }
                }),
                _c("character-length", { attrs: { value: row.notes } })
              ]
            }
          }
        ])
      }),
      _c("div", { staticClass: "mt-2 text-right" }, [
        _vm._v(
          " " +
            _vm._s(
              _vm.$t("lbl_total_items", {
                total: _vm.store.form.spareParts.length
              })
            ) +
            " "
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }