var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("a-input-number", {
    attrs: {
      value: _vm.value,
      parser: _vm.reverseFormatNumber,
      formatter: _vm.formatterNumber,
      precision: _vm.storeBaseDecimalPlace,
      disabled: _vm.disabled
    },
    on: { change: _vm.onChange }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }