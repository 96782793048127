var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "fieldset",
    [
      _c("legend", [_vm._v(" " + _vm._s(_vm.$t("lbl_unit")) + " ")]),
      _c("a-table", {
        attrs: {
          "data-source": _vm.store.form.units,
          columns: _vm.columns,
          pagination: false,
          size: "small",
          scroll: { y: 600, x: "calc(100% + 100px)" },
          "row-selection": {
            selectedRowKeys: _vm.selectedRowKeys,
            onChange: _vm.onSelectChange
          }
        },
        scopedSlots: _vm._u([
          {
            key: "title",
            fn: function() {
              return [
                _c(
                  "a-button-group",
                  [
                    _c("a-button", {
                      attrs: { icon: "plus", type: "primary" },
                      on: { click: _vm.addUnit }
                    }),
                    _c("a-button", {
                      attrs: { icon: "delete", type: "danger" },
                      on: { click: _vm.handleRemoveUnits }
                    })
                  ],
                  1
                )
              ]
            },
            proxy: true
          },
          {
            key: "nullable",
            fn: function(text) {
              return [_vm._v(" " + _vm._s(text || "-") + " ")]
            }
          },
          {
            key: "unitCode",
            fn: function(text, row) {
              return [
                _c("SelectWorkUnit", {
                  staticClass: "w-100",
                  attrs: {
                    "unit-customer-id": _vm.store.form.customer
                      ? _vm.store.form.customer.key
                      : "",
                    "unit-customer-loc": _vm.store.form.customerAddress
                  },
                  on: {
                    "update:meta": function(ev) {
                      return _vm.onChangeWorkUnit(row, ev)
                    }
                  },
                  model: {
                    value: row.asset,
                    callback: function($$v) {
                      _vm.$set(row, "asset", $$v)
                    },
                    expression: "row.asset"
                  }
                })
              ]
            }
          },
          {
            key: "unitSpecification",
            fn: function(text, row) {
              return [
                _c("a-input", {
                  attrs: {
                    placeholder: _vm.$t("common.insert-text", {
                      text: _vm.$t("lbl_specification")
                    }),
                    "allow-clear": ""
                  },
                  model: {
                    value: row.unitSpecification,
                    callback: function($$v) {
                      _vm.$set(row, "unitSpecification", $$v)
                    },
                    expression: "row.unitSpecification"
                  }
                })
              ]
            }
          }
        ])
      }),
      _c("div", { staticClass: "mt-2 text-right" }, [
        _vm._v(
          " " +
            _vm._s(
              _vm.$t("lbl_total_items", { total: _vm.store.form.units.length })
            ) +
            " "
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }