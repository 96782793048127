var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("Select", {
    attrs: {
      value: _vm.value,
      "allow-clear": "",
      "show-search": "",
      "filter-option": _vm.useLocalFilter,
      "option-filter-prop": "children",
      loading: _vm.loading,
      disabled: _vm.disabled,
      "label-in-value": "",
      options: _vm.options
    },
    on: { change: _vm.onChange },
    scopedSlots: _vm._u([
      {
        key: "option",
        fn: function(ref) {
          var option = ref.option
          return [_vm._v(_vm._s(option.label))]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }