

























































































import { SearchBuilder } from "@/builder";
import InputNumber from "@/components/custom/input/InputNumber.vue";
import { useInventory } from "@/hooks";
import MNotificationVue from "@/mixins/MNotification.vue";
import { Option } from "@/models/class/option.class";
import {
  State,
  WorkOrderSparepartState,
  WorkOrderUnitState,
} from "@/store/work-order/types";
import { InventoryLineResponseDto } from "@interface/inventory";
import { ProductStockResponseDto } from "@interface/master-product";
import { Component, Mixins } from "vue-property-decorator";
import { mapActions, mapGetters, mapState } from "vuex";
import SelectPartLocation from "./SelectPartLocation.vue";
import SelectPartUom from "./SelectPartUom.vue";
import SelectUnitSparepart from "./SelectUnitSparepart.vue";
import SelectWoPart from "./SelectWoPart.vue";

@Component({
  components: {
    SelectUnitSparepart,
    SelectWoPart,
    SelectPartUom,
    SelectPartLocation,
    InputNumber,
  },
  computed: {
    ...mapState({
      store: (st: any) => st.workOrderStore,
    }),
    ...mapGetters({
      getAvailableUnits: "workOrderStore/getAvailableUnits",
    }),
  },
  methods: {
    ...mapActions({
      addSparepart: "workOrderStore/addSparepart",
      removeSpareParts: "workOrderStore/removeSpareParts",
    }),
  },
})
export default class FormPart extends Mixins(MNotificationVue) {
  store!: State;
  getAvailableUnits!: Option<WorkOrderUnitState>[];
  addSparepart!: () => void;
  removeSpareParts!: (keys: string[]) => void;

  columns = [
    {
      title: this.$t("lbl_number_short"),
      dataIndex: "no",
      width: 90,
    },
    {
      title: this.$t("lbl_unit_code"),
      dataIndex: "unitCode",
      width: 200,
      scopedSlots: { customRender: "unitCode" },
    },
    {
      title: this.$t("lbl_part_number"),
      dataIndex: "productCode",
      width: 300,
      scopedSlots: { customRender: "productCode" },
    },
    {
      title: this.$t("lbl_part_name"),
      dataIndex: "productName",
      width: 300,
      scopedSlots: { customRender: "productName" },
    },
    {
      title: this.$t("lbl_uom"),
      dataIndex: "productUom",
      width: 100,
      scopedSlots: { customRender: "productUom" },
    },
    {
      title: this.$t("lbl_part_location"),
      dataIndex: "productLocation",
      width: 300,
      scopedSlots: { customRender: "productLocation" },
    },
    {
      title: this.$t("lbl_qty_wo"),
      dataIndex: "qtyWorkOrder",
      width: 100,
      scopedSlots: { customRender: "qtyWorkOrder" },
    },
    {
      title: this.$t("lbl_qty_available"),
      dataIndex: "qtyAvailable",
      width: 150,
      scopedSlots: { customRender: "number" },
    },
    {
      title: this.$t("lbl_notes"),
      dataIndex: "notes",
      width: 200,
      scopedSlots: { customRender: "notes" },
    },
  ];

  selectedRowKeys: Array<string> = [];

  onSelectChange(keys: Array<string>): void {
    this.selectedRowKeys = keys;
  }

  handleRemove(): void {
    this.showConfirmationDeleteItems(() => {
      this.removeSpareParts(this.selectedRowKeys);
      this.selectedRowKeys = [];
    });
  }

  onChangeUnitSparepart(
    row: WorkOrderSparepartState,
    ev?: Option<WorkOrderUnitState>
  ): void {
    row.unitCode = ev?.meta?.unitCode ?? "";
  }

  onChangeProduct(
    row: WorkOrderSparepartState,
    ev?: Option<ProductStockResponseDto>
  ): void {
    row.productName = undefined;
    row.productCode = undefined;
    row.productUom = undefined;
    row.productLocation = undefined;
    row.qtyAvailable = 0;

    if (ev && ev.meta) {
      const {
        name,
        code,
        description,
        id,
        uomId,
        uomName,
        defaultLocation,
        defaultLocationId,
      } = ev.meta;
      row.productName = {
        label: `${name} (${description})`,
        key: id,
      };
      row.productCode = {
        label: code,
        key: id,
      };
      row.productUom = {
        label: uomName,
        key: uomId,
      };
      row.productLocation = {
        label: defaultLocation,
        key: defaultLocationId,
      };
    }

    if (row.productLocation)
      [
        this.findInventory(
          {
            rackId: row.productLocation?.key,
            uomId: row.productUom?.key ?? "",
            productId: row.productCode?.key ?? "",
          },
          ({ available }) => {
            row.qtyAvailable = available || 0;
          }
        ),
      ];
  }

  async findInventory(
    {
      rackId,
      uomId,
      productId,
    }: { rackId: string; uomId: string; productId: string },
    cb: (data: InventoryLineResponseDto) => void
  ): Promise<void> {
    const { findAllInventoryLine } = useInventory();
    const findByRack = new SearchBuilder()
      .push(["warehouseLocation.secureId", rackId])
      .and()
      .push(["uom.secureId", uomId])
      .and()
      .push(["product.secureId", productId])
      .and()
      .push(["available", "0"], { ht: true })
      .build();
    const { totalElements, data } = await findAllInventoryLine({
      search: findByRack,
    });
    if (totalElements > 0) {
      cb(data[0]);
    }
  }

  onChangeUom(row: WorkOrderSparepartState): void {
    row.productLocation = undefined;
    row.qtyAvailable = 0;
  }

  onChangeProductLocation(
    row: WorkOrderSparepartState,
    ev?: Option<InventoryLineResponseDto>
  ): void {
    row.qtyAvailable = ev?.meta?.available ?? 0;
  }
}
