














import Select from "@/components/custom/select/Select.vue";
import { useLocalFilter } from "@/hooks";
import { Option } from "@/models/class/option.class";
import { WorkOrderUnitState } from "@/store/work-order/types";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  components: {
    Select,
  },
})
export default class SelectUnitSparepart extends Vue {
  useLocalFilter = useLocalFilter;

  @Prop({ required: false, type: String, default: undefined })
  value!: string | undefined;

  @Prop({ required: false, type: Boolean, default: false })
  disabled!: boolean;

  @Prop({ required: false, type: Array as () => Option[], default: [] })
  options!: Option<WorkOrderUnitState>[];

  emits(e: string): void {
    this.$emit("input", e);
    this.$emit("change", e);
    this.$emit("update:meta", this.findOption(e));
  }

  findOption(val: string): Option<WorkOrderUnitState> | undefined {
    return this.options.find(item => item.value === val);
  }
}
