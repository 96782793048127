











import {
  formatterNumber,
  reverseFormatNumber,
} from "@/validator/globalvalidator";
import { Component, Prop, Vue } from "vue-property-decorator";
import { mapState } from "vuex";

@Component({
  computed: {
    ...mapState({
      storeBaseDecimalPlace: (st: any) =>
        st.preferenceStore.baseDecimalPlace as number,
    }),
  },
})
export default class InputNumber extends Vue {
  reverseFormatNumber = reverseFormatNumber;
  formatterNumber = formatterNumber;

  storeBaseDecimalPlace!: number;

  @Prop({ required: true, type: Number, default: 0 })
  value!: number;

  @Prop({ required: false, type: Boolean, default: false })
  disabled!: boolean;

  onChange(e: number | null): void {
    this.$emit("input", e);
    this.$emit("change", e);
  }
}
