



















































































































import SelectBranch from "@/components/custom/select/SelectBranch.vue";
import SelectCustomer from "@/components/custom/select/SelectCustomer.vue";
import SelectMechanic from "@/components/custom/select/SelectMechanic.vue";
import { useContactData, useDisabledBackDate } from "@/hooks";
import MNotificationVue from "@/mixins/MNotification.vue";
import { Option } from "@/models/class/option.class";
import { DEFAULT_DATE_FORMAT } from "@/models/constants/date.constant";
import { State, WorkOrderFormState } from "@/store/work-order/types";
import { LabelInValue } from "@/types";
import {
  AddressDataDto,
  DetailContactDataDto,
  ListContactDataDto,
} from "@interface/contact-data";
import { AssetResponseDto } from "@interface/master-asset";
import { FormModel } from "ant-design-vue";
import { Component, Mixins, Ref } from "vue-property-decorator";
import { mapMutations, mapState } from "vuex";
import SelectUnit from "./SelectUnit.vue";

@Component({
  components: {
    SelectBranch,
    SelectUnit,
    SelectCustomer,
    SelectMechanic,
  },
  computed: {
    ...mapState({
      store: (st: any) => st.workOrderStore,
    }),
  },
  methods: {
    ...mapMutations({
      setForm: "workOrderStore/setForm",
    }),
  },
})
export default class FormHeader extends Mixins(MNotificationVue) {
  DEFAULT_DATE_FORMAT = DEFAULT_DATE_FORMAT;
  useDisabledBackDate = useDisabledBackDate;

  store!: State;
  setForm!: (fields: Partial<WorkOrderFormState>) => void;

  @Ref("formModel")
  formModel!: FormModel;

  formRules = {
    branch: [
      { required: true, message: this.$t("lbl_validation_required_error") },
    ],
    workOrderDate: [
      { required: true, message: this.$t("lbl_validation_required_error") },
    ],
    mechanic: [
      { required: true, message: this.$t("lbl_validation_required_error") },
    ],
    scheduleOrder: [
      { required: true, message: this.$t("lbl_validation_required_error") },
    ],
    description: [
      { required: true, message: this.$t("lbl_validation_required_error") },
    ],
  };

  custAddresses: AddressDataDto[] = [];

  getDetailCust(custId: string, cb: (dt: DetailContactDataDto) => void): void {
    const { findOne } = useContactData();
    findOne(custId).then(cb);
  }

  /**
   * @description
   * get pic name from selected customer address
   */
  getDefaultPic(
    customerAddress: string,
    shippingAddress: AddressDataDto[]
  ): string {
    if (!Array.isArray(shippingAddress) || shippingAddress.length === 0) {
      return "";
    }
    const address = shippingAddress.find(
      item =>
        item.shipTo &&
        item.location?.toUpperCase() === customerAddress?.toUpperCase()
    );
    return address?.picName ?? "";
  }

  setDefaultPic(addresses: AddressDataDto[]): void {
    this.setForm({
      picName: this.getDefaultPic(this.store.form.customerAddress, addresses),
    });
  }

  onChangeUnitCode(ev?: Option<AssetResponseDto>): void {
    let customer!: LabelInValue;
    let customerAddress = "";

    if (ev && ev.meta && ev.meta.customerId) {
      customer = {
        key: ev.meta.customerId,
        label: ev.meta.customerName || ev.meta.customerFirstName,
      };
      customerAddress = ev.meta.customerLocation;
    }

    this.setForm({
      customer,
      customerAddress,
    });

    if (!this.store.form.customer) return;
    this.getDetailCust(this.store.form.customer.key, ({ addressDataList }) => {
      this.setDefaultPic(addressDataList ?? []);
    });
  }

  onChangeCustomer(ev?: Option<ListContactDataDto>): void {
    const { getDefaultShipToAddress } = useContactData();
    this.setForm({
      customerAddress: undefined,
      picName: undefined,
      unitCode: undefined,
    });
    if (ev && ev.meta) {
      this.setForm({
        customerAddress:
          getDefaultShipToAddress(ev.meta.addressDataList ?? []) ?? undefined,
      });
    }
  }
}
